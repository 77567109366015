/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto'), url('~assets/fonts/Roboto/roboto-v20-latin-300.woff2') format('woff2'),
    url('~assets/fonts/Roboto/roboto-v20-latin-300.woff') format('woff');
}

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), url('~assets/fonts/Roboto/roboto-v20-latin-regular.woff2') format('woff2'),
    url('~assets/fonts/Roboto/roboto-v20-latin-regular.woff') format('woff');
}

/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto'), url('~assets/fonts/Roboto/roboto-v20-latin-500.woff2') format('woff2'),
    url('~assets/fonts/Roboto/roboto-v20-latin-500.woff') format('woff');
}

/* roboto-bold - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto'), url('~assets/fonts/Roboto/roboto-v20-latin-700.woff2') format('woff2'),
    url('~assets/fonts/Roboto/roboto-v20-latin-700.woff') format('woff');
}
