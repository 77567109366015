.card-title {
  font-size: 24px;
}

.card-title-logo {
  color: #001e4bbe;
  font-size: 24px;
}

.list-group {
  .list-group-item__header {
    font-size: 18px;
    font-weight: 600;
    color: #191919;
  }
}

.card-start-page {
  height: calc(100vh - 111px);
  width: calc((100vw - (4 * 24px)) / 3);
}
