/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans'), url('~assets/fonts/Open_Sans/open-sans-v18-latin-300.woff2') format('woff2'),
    url('~assets/fonts/Open_Sans/open-sans-v18-latin-300.woff') format('woff');
}

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), url('~assets/fonts/Open_Sans/open-sans-v18-latin-regular.woff2') format('woff2'),
    url('~assets/fonts/Open_Sans/open-sans-v18-latin-regular.woff') format('woff');
}

/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans'), url('~assets/fonts/Open_Sans/open-sans-v18-latin-600.woff2') format('woff2'),
    url('~assets/fonts/Open_Sans/open-sans-v18-latin-600.woff') format('woff');
}

/* open-sans-bold - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans'), url('~assets/fonts/Open_Sans/open-sans-v18-latin-700.woff2') format('woff2'),
    url('~assets/fonts/Open_Sans/open-sans-v18-latin-700.woff') format('woff');
}
