/* You can add global styles to this file, and also import other style files */
@import './tables';
@import './variables';
@import './dropdown';
@import './cards';
@import '~bootstrap/scss/bootstrap';
@import '~assets/fonts/fonts';
@import './theme';

@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/assets/icons.css';

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  height: 100vh;
}

body {
  background: #eeeff1;
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  font-size: 15px;
}
.font-weight-bold {
  font-weight: 600 !important;
}

.wrapper {
  padding: 1.5% 0 1.5%;
  flex-grow: 1;
}

.sticky-footer {
  padding: 0;
  margin: 0;
  top: 0px;
  bottom: 0;
  width: 100%;
}

.page {
  display: flex;
  flex-direction: column;
  background: #f2f3f5 0% 0% no-repeat padding-box;
  margin: 0 auto;
  overflow-y: auto;
  height: 100%;

  &__header {
    flex: 0 1 auto;
    background: #ffffff;
    padding: 15px 15px;
    border-bottom: 1px solid #cccccc;
  }

  &__body {
    flex: 1 1 auto;
    background: #f2f3f5 0% 0% no-repeat padding-box;
    padding: 15px 15px;

    &__container {
      max-width: 1400px;
    }
  }

  &__footer {
    flex: 0 1 auto;
    background: #ffffff;
    padding: 15px 15px;
    border-top: 1px solid #cccccc;
  }
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: #191919;
  // font-weight: 600 !important;
}

.card-header {
  border-bottom: 1px solid #cccccc;
}
.border-bottom {
  border-bottom-color: #cccccc;
}

mat-panel-title {
  font-weight: 600;
  font-size: 1.125rem;
}

.mat-expansion-panel-content {
  font-size: 15px !important;
}

.mat-horizontal-stepper-header {
  pointer-events: none !important;
}

.mat-table {
  border: 1px solid #dee2e6;
}

.mat-menu-item {
  padding-right: 32px !important;
}
.mat-tab-label-container {
  background-color: white;
}

.text-transformation-none .mat-button-wrapper {
  text-transform: none !important;
}
.mat-button-wrapper {
  text-transform: uppercase;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #3f51b5;
}

.mat-stroked-button.mat-primary {
  background-color: white;
}

.info-text {
  color: #000000a6;
}
.super {
  vertical-align: super;
  font-size: small;
}

.text-red {
  color: #e10019;
}

strong {
  font-weight: 600;
}

.gg-chevron-double-right {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
}
.gg-chevron-double-right::after,
.gg-chevron-double-right::before {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 8px;
  border-right: 2px solid;
  border-top: 2px solid;
  transform: rotate(45deg);
  top: 7px;
  right: 6px;
}
.gg-chevron-double-right::after {
  right: 11px;
}

.gg-chevron-double-left {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
}
.gg-chevron-double-left::after,
.gg-chevron-double-left::before {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 8px;
  border-left: 2px solid;
  border-bottom: 2px solid;
  transform: rotate(45deg);
  top: 7px;
  left: 6px;
}
.gg-chevron-double-left::after {
  left: 11px;
}

.bg-blue {
  background: #ccd4de;
}

.bg-grey {
  background-color: #e3e3e3;
}

.content {
  flex: 1 0 auto;
}

.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default;
}

textarea {
  resize: none !important;
}

.page-header,
.page-footer {
  background-color: white;
  flex-shrink: 0;
}

.page-body {
  flex-grow: 1;
}

.scroll {
  overflow-y: auto;
}

.active-tab {
  border-bottom: 4px solid $primary;
  color: $primary;
}

.nav-tab:hover {
  color: $primary-hover;
}
.active-tab:hover {
  border-bottom-color: $primary-hover;
}

.md-10 {
  font-size: 10px !important;
}

.star-favorite {
  font-size: 1.5rem;
}

.star-favorite.checked {
  color: orange;
}

.form-container {
  max-width: 1000px;
}

.favorite {
  min-width: 40px !important;
  background-color: #f2f3f5;
  &__icon {
    color: #000000a6;
  }

  &__icon.active {
    color: orange;
  }
}

.mat-button-toggle-checked {
  background-color: #ffffff;
  color: #006cd9 !important;
}

.mat-button-toggle {
  color: #000000de;
}

.notification-success {
  background-color: #5cb85c;
  color: white;
}
.notification-error {
  background-color: #d9534f;
  color: white;
}

.notification-error,
.notification-success {
  .mat-simple-snackbar-action {
    color: white !important;
  }
}
