$primary: #0085c0;
$secondary: #00549c;
$tertiary: #eeeff1;
$info: #fff7b7;
$red: #ed0027;

$primary-hover: darken($primary, 10);

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'tertiary': $tertiary,
  'info': $info,
);

// Card
$card-cap-bg: #ebeef2;

$headings-color: #003063;

$breadcrumb-bg: white;
$breadcrumb-margin-bottom: 0;
$breadcrumb-padding-y: 0;
$breadcrumb-padding-x: 0;
