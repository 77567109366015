@import './variables';
@import '~@ng-select/ng-select/themes/material.theme.css';

.ng-select.ng-select-focused .ng-select-container:after {
  border-color: $secondary;
  border-width: 2px;
}

.ng-select.ng-select-focused .ng-select-container.ng-appearance-outline:after,
.ng-select.ng-select-focused .ng-select-container.ng-appearance-outline:hover:after {
  border-color: $secondary;
  border-width: 2px;
}

.ng-select.ng-select-focused .ng-select-container .ng-value-container .ng-placeholder {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  color: $secondary;
}

.ng-select.ng-select-focused .ng-select-container .ng-arrow-wrapper .ng-arrow {
  color: $secondary;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  border-radius: 16px;
  color: #191919;
  padding-top: 4px;
  padding-right: 10px;
  padding-left: 6px;
  padding-bottom: 6px;
  margin: 0 0.4375em 0.4375em 0;
  background-color: #cccccc;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
  color: #191919;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
  font-weight: 400;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected {
  background: rgba(0, 0, 0, 0.12);
  color: $secondary;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  background: rgba(0, 0, 0, 0.12);
  color: $secondary;
}
