@use '@angular/material' as mat;

/* For use in src/lib/core/theming/_palette.scss */
$md-testblue: (
  50: #e0edfa,
  100: #b3d3f4,
  200: #80b6ec,
  300: #4d98e4,
  400: #2682df,
  500: #006cd9,
  600: #0064d5,
  700: #0059cf,
  800: #004fca,
  900: #003dc0,
  A100: #e9eeff,
  A200: #b6c8ff,
  A400: #83a1ff,
  A700: #698eff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$my-primary: mat.define-palette($md-testblue);
$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The "warn" palette is optional and defaults to red if not specified.
$my-warn: mat.define-palette(mat.$red-palette);

$my-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
      warn: $my-warn,
    ),
  )
);

@include mat.core-theme($my-theme);
@include mat.all-component-themes($my-theme);
